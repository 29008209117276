
  
  .typography {
    color: #1E90FF; /* Dodger Blue text color */
  }
  
  .title {
    font-family: 'JAncient';
    color: #1e90ff;
    margin-bottom: 20px;
  }

  .form {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }

  .button {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }

  .text-field{
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }

  h1 {
    font-size: 4rem;
    margin-top: 1rem;
  }
  
  @media (max-width: 600px) {
    h1 {
      font-size: 2rem;
    }
  }
  