/* Import the custom font using @font-face */
@font-face {
  font-family: 'Jancient';
  src: url('./fonts/jancient-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Apply the custom font globally */
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  font-family: 'Jancient', sans-serif; /* Set the custom font */
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
}

.MuiBox-root {
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns the content to the top */
  align-items: left;
}

.MuiTypography-root {
  font-family: 'Jancient', sans-serif; /* Ensure custom font is applied */
}

h1 {
  font-size: 4rem; /* Large screens */
  margin-top: 1rem; /* Adds some margin at the top */
}

p {
  color: #1e90ff;
}

@media (max-width: 600px) {
  h1 {
      font-size: 2rem; /* Mobile screens */
  }
}
